import React, { useState, useEffect, useRef } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setExpenses, addExpense, } from "../../redux/slices/expenseSlice"; // Import actions from the product slice
import useAuth from "../../modules/auth/hooks/useAuth";

 
interface Expense {
	id: number;
	user?: {
		id: number;
		first_name: string;
		last_name: string;
		email: string;
	};
	category?: {
		id: number;
		name: string;
		description: string;
	};
	amount: number;
	receipt: string;
	description: string;
	date: string;
	status: string;
	user_position: string;
}

const Expenses: React.FC = () => {
	const { auth, saveAuth } = useAuth();
	const [expenses, setLocalExpenses] = useState<Expense[]>([]);
	const [loading, setLoading] = useState(true);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
 
	const [newExpense, setNewExpense] = useState<Expense>({
		id: 0,
		user:{
			id: 0,
			first_name:"",
			last_name:"",
			email:"",
		},
		category:{
			id: 0,
			name: "",
			description: "",
		},
		amount: 0,
		receipt: "",
		description: "",
		date: "",
		status: "",
		user_position: "",
	});
	const dispatch = useDispatch(); // Use dispatch to send actions to Redux

 
  

	// Fetch products from the API and store them in Redux
	useEffect(() => {
		const fetchExpenses = async () => {
			try {
				const token = auth?.token;
				const response = await axios.get("/expense/expenses/", {
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + token,
					},
				});
				setLocalExpenses(response.data); // Update local state
				dispatch(setExpenses(response.data)); // Save data to Redux
				setLoading(false);
			} catch (error) {
				console.error("Error fetching expenses:", error);
				setLoading(false);
			}
		};

		fetchExpenses();
	}, [dispatch]);


	if (loading) {
		return <p>Loading expenses...</p>; // Display a loading message
	}


	return (
		<div className="flex flex-col max-h-max max-w-max justify-center rounded-lg shadow-md p-6 dark:border dark:border-gray-800 bg-white dark:bg-zinc-900 ">

 


				<div className="card mb-5 mb-xl-8">
					{/*-begin::Header*/}
					<div className="card-header border-0 pt-5">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label fw-bold fs-3 mb-1">Xərclərin siyahısı</span>
							<span className="text-muted mt-1 fw-semibold fs-7">Bütün xərclərin siyahısı göstərilib</span>
						</h3>
						<div className="card-toolbar">
							<a href="#" className="btn btn-sm btn-light-primary">
								<i className="ki-duotone ki-plus fs-2"></i>Xərc əlavə et</a>
						</div>
					</div>
					{/*-end::Header*/}
					{/*-begin::Body*/}
					<div className="card-body py-3">
						{/*-begin::Table container*/}
						<div className="table-responsive">
							{/*-begin::Table*/}
							<table className="table table-row-dashed align-middle gs-0 gy-4">
								{/*-begin::Table head*/}
								<thead>
									<tr className="fw-bold text-muted bg-light">
										<th className="ps-4 min-w-325px rounded-start">Təyinat</th>
										<th className="min-w-125px">Miqdar</th>
										<th className="min-w-125px">Əməliyyatçı</th>
										<th className="min-w-125px">Tarix</th>
										<th className="min-w-100px">Status</th>
										<th className="min-w-100px text-end rounded-end"></th>
									</tr>
								</thead>
								{/*-end::Table head*/}
								{/*-begin::Table body*/}
								<tbody>
									{expenses.map((expense) => (


										<tr key={expense.id}>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<div className="d-flex align-items-center">
													<div className="symbol symbol-40px me-5">
														<img src="/assets/file.png" className="" alt="" />
													</div>
													<div className="d-flex justify-content-start flex-column">
														<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">{expense.category?.name}</a>
														<span className="text-muted fw-semibold text-muted d-block fs-7">{expense.description}</span>
													</div>
												</div>
											</td>
											<td className="" style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">₼ {expense.amount}</a>
												<span className="text-muted fw-semibold text-muted d-block fs-7">
													{expense.status == 'paid'?<>
														<span className="badge badge-light-success fs-8 ">Ödənilib</span>
														</>:
														<span className="badge badge-light-warning fs-9 ">Gözələmədə</span>}
												</span>
											</td>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">{expense.user?.first_name} {expense.user?.last_name}</a>
												<span className="text-muted fw-semibold text-muted d-block fs-7">{expense.user_position}</span>
											</td>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6"></a>
												<span className="text-muted fw-semibold text-muted d-block fs-7">{expense.date}</span>
											</td>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												{expense.status == "paid"?<span className="badge badge-light-success fs-7 fw-bold">Ödənilib</span>:
												<span className="badge badge-light-warning fs-7 fw-bold">Gözləmədə</span>
												}
												
											</td>
											<td className="text-end" style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
													<i className="ki-duotone ki-switch fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
												</a>
												<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
													<i className="ki-duotone ki-pencil fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
												</a>
												<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
													<i className="ki-duotone ki-trash fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
													</i>
												</a>
											</td>
										</tr>
									))}


								</tbody>
								{/*-end::Table body*/}
							</table>
							{/*-end::Table*/}
						</div>
						{/*-end::Table container*/}
					</div>
					{/*-begin::Body*/}
				</div>
			</div>


	);
};

export default Expenses;
