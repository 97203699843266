import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../modules/auth/hooks/useAuth";

const PublicRoute: React.FC = () => {
	const { auth } = useAuth();

	return !auth?.token ? <Outlet /> : <Navigate to="/app/dashboard" />;
};

export default PublicRoute;
