import React, { useState, useEffect } from 'react';
import axios from 'axios';
import useAuth from "../../modules/auth/hooks/useAuth";


interface PurchaseOrderItem {
    product: string;   // Product ID or name
    quantity: number;
    price_per_unit: number;
}

interface PurchaseOrder {
    vendor: string;    // Vendor ID or name
    order_number: string;
    date: string;      // Date in YYYY-MM-DD format
    delivery_date?: string;  // Optional delivery date
    items: PurchaseOrderItem[];
    description?: string;
}

const AddPurchaseOrder: React.FC = () => {
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>({
        vendor: '',
        order_number: '',
        date: '',
        items: [{ product: '', quantity: 1, price_per_unit: 0 }]
    });
    const [vendors, setVendors] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);

    const { auth, saveAuth } = useAuth();
    const organizationId = 1; // Assuming this is where you get the organization ID

    useEffect(() => {
        // Fetch vendors and products on component mount
        const fetchVendors = async () => {
            try {
                const response = await axios.get(`/purchase/vendors?org_id=${organizationId}`, {
                    headers: {
                        'Authorization': `Bearer ${auth?.token}`,
                    },
                });
                setVendors(response.data);
            } catch (error) {
                console.error('Error fetching vendors:', error);
            }
        };

        const fetchProducts = async () => {
            try {
                const response = await axios.get(`/catalog/products?org_id=${organizationId}`, {
                    headers: {
                        'Authorization': `Bearer ${auth?.token}`,
                    },
                });
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchVendors();
        fetchProducts();
    }, [auth?.token, organizationId]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setPurchaseOrder((prev) => ({ ...prev, [name]: value }));
    };

    const handleItemChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
    
        const updatedItems = purchaseOrder.items.map((item, i) => {
            if (i === index) {
                if (name === 'product') {
                    // Find the selected product to get its cost
                    const selectedProduct = products.find((product) => product.id.toString() === value);
                    const price_per_unit = selectedProduct ? selectedProduct.cost : 0;
                    return { ...item, product: value, price_per_unit };
                }
                return { ...item, [name]: value };
            }
            return item;
        });
    
        setPurchaseOrder((prev) => ({ ...prev, items: updatedItems }));
    };
    

    const addItem = () => {
        setPurchaseOrder((prev) => ({
            ...prev,
            items: [...prev.items, { product: '', quantity: 1, price_per_unit: 0 }]
        }));
    };

    const removeItem = (index: number) => {
        const updatedItems = purchaseOrder.items.filter((_, i) => i !== index);
        setPurchaseOrder((prev) => ({ ...prev, items: updatedItems }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const token = auth?.token;

            // First, create the purchase order without items
            const formData = new FormData();
            formData.append('vendor', purchaseOrder.vendor);
            formData.append('order_number', purchaseOrder.order_number);
            formData.append('date', purchaseOrder.date);
            if (purchaseOrder.delivery_date) {
                formData.append('delivery_date', purchaseOrder.delivery_date);
            }
            formData.append('description', purchaseOrder.description || '');
            formData.append('organization', organizationId.toString());

            const purchaseResponse = await axios.post('/purchase/purchases/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (purchaseResponse.status === 201) {
                const purchaseId = purchaseResponse.data.id;

                // Now, create each purchase item
                for (let item of purchaseOrder.items) {
                    const itemData = new FormData();
                    itemData.append('purchase', purchaseId); // Associate the item with the created purchase
                    itemData.append('product', item.product); // Ensure the product ID is valid
                    itemData.append('quantity', item.quantity.toString());
                    itemData.append('price_per_unit', item.price_per_unit.toString());

                    await axios.post('/purchase/purchase-items/', itemData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${token}`,
                        }
                    });
                }

                alert('Purchase order and items created successfully!');
            } else {
                alert('Failed to create purchase order');
            }
        } catch (error) {
            console.error('Error submitting purchase order:', error);
        }
    };

    return (
        <div>
            <h1>Add Purchase Order</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Vendor:</label>
                    <select
                        name="vendor"
                        value={purchaseOrder.vendor}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select Vendor</option>
                        {vendors.map((vendor) => (
                            <option key={vendor.id} value={vendor.id}>
                                {vendor.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label>Order Number:</label>
                    <input
                        type="text"
                        name="order_number"
                        value={purchaseOrder.order_number}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div>
                    <label>Date:</label>
                    <input
                        type="date"
                        name="date"
                        value={purchaseOrder.date}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div>
                    <label>Delivery Date:</label>
                    <input
                        type="date"
                        name="delivery_date"
                        value={purchaseOrder.delivery_date || ''}
                        onChange={handleInputChange}
                    />
                </div>

                <div>
                    <h3>Items</h3>
                    {purchaseOrder.items.map((item, index) => (
                        <div key={index}>
                            <select
                                name="product"
                                value={item.product}
                                onChange={(e) => handleItemChange(index, e)}
                                required
                            >
                                <option value="">Select Product</option>
                                {products.map((product) => (
                                    <option key={product.id} value={product.id}>
                                        {product.name}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="number"
                                name="quantity"
                                placeholder="Quantity"
                                value={item.quantity}
                                onChange={(e) => handleItemChange(index, e)}
                                required
                            />
                            <input
                                type="number"
                                name="price_per_unit"
                                placeholder="Price per unit"
                                value={item.price_per_unit}
                                onChange={(e) => handleItemChange(index, e)}
                                required
                            />
                            <button type="button" onClick={() => removeItem(index)}>Remove</button>
                        </div>
                    ))}
                    <button type="button" onClick={addItem}>Add Item</button>
                </div>

                <div>
                    <label>Description:</label>
                    <textarea
                        name="description"
                        value={purchaseOrder.description || ''}
                        onChange={handleInputChange}
                    />
                </div>

                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default AddPurchaseOrder;
