import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import PrivateRoutes from "./routing/PrivateRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { AuthPage } from "./modules/auth/pages/AuthPage";
import Dashboard from "./pages/dashboard/Dashboard";
import Products from "./pages/catalog/Products";
import Categories from "./pages/Categories";
import Expenses from "./pages/expense/Expenses";
import Purchases from "./pages/purchase/Purchases";
import Sales from "./pages/sales/Sales";
import AddPurchaseOrder from "./pages/purchase/AddPurchase";
import AddSalesOrder from "./pages/sales/AddSale";

const App: React.FC = () => {
	return (
		<Routes>
			{/* Public Routes */}
			<Route path="/login/*" element={<PublicRoutes />}>
				<Route path="*" element={<AuthPage />} />
			</Route>
			{/* Private Routes */}
			<Route path="/app/*" element={<PrivateRoutes />}>
				<Route index path="products/list" element={<Products />} />
				<Route index path="expenses/list" element={<Expenses />} />
				<Route index path="purchases/list" element={<Purchases />} />
				<Route index path="purchases/add" element={<AddPurchaseOrder />} />
				<Route index path="sales/list" element={<Sales />} />
				<Route index path="sales/add" element={<AddSalesOrder />} />
				<Route index path="categories" element={<Categories />} />
				<Route path="dashboard" element={<Dashboard />} />
			</Route>
			{/* Redirect to /app/dashboard by default */}
			<Route path="/" element={<Navigate to="/app/" />} />
		</Routes>
	);
};

export default App;
