import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./modules/auth/providers/AuthProvider";
import AuthInit from "./modules/auth/providers/AuthInit";
import { Provider } from "react-redux";
import store from "./redux/store";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://api.trivasoft.com/api";
axios.defaults.headers.common["Content-Type"] = "application/json";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<AuthProvider>
				<AuthInit>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</AuthInit>
			</AuthProvider>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
