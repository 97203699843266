import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Expense {
	id: number;
	user: number;
	amount: number;
	receipt: string;
	description: string;
	date: string;
	status: string;
}
interface ExpenseState {
	expenses: Expense[];
}

const initialState: ExpenseState = {
	expenses: [],
};

const expenseSlice = createSlice({
	name: "expenses",
	initialState,
	reducers: {
		setExpenses(state, action: PayloadAction<Expense[]>) {
			state.expenses = action.payload;
		},
		addExpense(state, action: PayloadAction<Expense>) {
			state.expenses.push(action.payload);
		},
		updateExpense(state, action: PayloadAction<Expense>) {
			const index = state.expenses.findIndex(
				(expense) => expense.id === action.payload.id
			);
			if (index !== -1) {
				state.expenses[index] = action.payload;
			}
		},
		removeExpense(state, action: PayloadAction<number>) {
			state.expenses = state.expenses.filter(
				(expense) => expense.id !== action.payload
			);
		},
	},
});

export const { setExpenses, addExpense, updateExpense, removeExpense } =
	expenseSlice.actions;
export default expenseSlice.reducer;
