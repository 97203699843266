import React, { useState, useEffect, FC } from "react";
import AuthContext from "../contexts/AuthContext";
import { AuthToken, User } from "../types/authTypes";
import { useDispatch } from "react-redux";
import {
	refreshToken,
	getAuthCookies,
	setAuthCookies,
	removeAuthCookies,
	getUserByToken,
} from "../api/authRequests";
import { setUser } from "../../../redux/slices/authSlice";

type WithChildren<T = {}> = T & { children?: React.ReactNode };

const AuthProvider: FC<WithChildren> = ({ children }) => {
	const [auth, setAuth] = useState<AuthToken | undefined>(
		() => getAuthCookies() || undefined
	);
	const [currentUser, setCurrentUser] = useState<User | undefined>();
	const dispatch = useDispatch();

	const saveAuth = (auth: AuthToken | undefined) => {
		setAuth(auth);
		if (auth) {
			setAuthCookies(auth);
		} else {
			removeAuthCookies();
		}
	};

	const logout = () => {
		saveAuth(undefined);
		setCurrentUser(undefined);
	};

	// Function to handle token refresh
	const handleTokenRefresh = async () => {
		try {
			if (auth?.refresh) {
				const newAccessToken = await refreshToken();
				if (newAccessToken) {
					setAuth((prevAuth) => ({
						...prevAuth,
						token: newAccessToken,
					}));
				} else {
					logout();
				}
			}
		} catch (error) {
			console.error("Error during token refresh:", error);
			logout();
		}
	};

	useEffect(() => {
		// Set interval to refresh token every 5 minutes (300000 ms)
		const intervalId = setInterval(handleTokenRefresh, 300000);

		// Clear interval on component unmount
		return () => clearInterval(intervalId);
	}, [auth]);

	return (
		<AuthContext.Provider
			value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
