import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import useAuth from "../../modules/auth/hooks/useAuth";

interface Product {
    id: number;
    image: string;
    brand: {
        id:number,
        name: string;
    };
    lowest_category: {
        id:number,
        name: string;
    };
    name: string;
    sku: string;
    barcode: string;
    cost: number;
    price: number;
    stock: number;
    product_measurement: {
        id:number,
        name: string;
        symbol: string;
    };
    product_type_name: string;
    is_active: boolean;
}

const Products: React.FC = () => {
    const { auth } = useAuth(); // Authentication hook to get token
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState(true);

    // Fetch products from API
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const token = auth?.token;
                const response = await axios.get("/catalog/products/?org_id=1", {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                });
                setProducts(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching products:", error);
                setLoading(false);
            }
        };

        fetchProducts();
    }, [auth?.token]);

    if (loading) {
        return <p>Loading products...</p>;
    }

    return (
        <div className="flex flex-col max-h-max max-w-max justify-center rounded-lg shadow-md p-6 dark:border dark:border-gray-800 bg-white dark:bg-zinc-900 ">

 


				<div className="card mb-5 mb-xl-8">
					{/*-begin::Header*/}
					<div className="card-header border-0 pt-5">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label fw-bold fs-3 mb-1">Məhsulların siyahısı</span>
							<span className="text-muted mt-1 fw-semibold fs-7">Bütün məhsulların siyahısı göstərilib</span>
						</h3>
						<div className="card-toolbar">
							<a href="#" className="btn btn-sm btn-light-primary" style={{marginRight:"10px"}}>
								<i className="ki-duotone ki-plus fs-2"></i>Məhsul əlavə et</a>
                                <a href="#" className="btn btn-sm btn-light-primary">
								<i className="ki-duotone ki-plus fs-2"></i>Kateqoriya əlavə et</a>
						</div>
                        
					</div>
					{/*-end::Header*/}
					{/*-begin::Body*/}
					<div className="card-body py-3">
						{/*-begin::Table container*/}
						<div className="table-responsive">
							{/*-begin::Table*/}
							<table className="table table-row-dashed align-middle gs-0 gy-4">
								{/*-begin::Table head*/}
								<thead>
									<tr className="fw-bold text-muted bg-light">
										<th className="ps-4 min-w-250px rounded-start">Məhsul adı və kateqoriyası</th>
										<th className="min-w-100px">SKU</th>
										<th className="min-w-100px">Növü</th>
										<th className="min-w-100px">Ölçü</th>
										<th className="min-w-100px">Alış ₼</th>
										<th className="min-w-100px">Satış ₼</th>
										<th className="min-w-100px">Qalıq</th>
										<th className="min-w-100px text-end rounded-end"></th>
									</tr>
								</thead>
								{/*-end::Table head*/}
								{/*-begin::Table body*/}
								<tbody>
									{products.map((product) => (


										<tr key={product.id}>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<div className="d-flex align-items-center">
													<div className="symbol symbol-30px me-5">
														<img src="/assets/box.png" className="" alt="" />
													</div>
													<div className="d-flex justify-content-start flex-column">
														<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">{product.name}</a>
														<span className="text-muted fw-semibold text-muted d-block fs-7">{product.lowest_category?.name}</span>
													</div>
												</div>
											</td>
											<td className="" style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">{product.sku}</a>
											</td>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">{product.product_type_name}</a>
											</td>
                                            <td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<span className="text-muted fw-semibold text-muted d-block fs-7">{product.product_measurement?.name}</span>
											</td>
                                            <td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">{(product.cost).toFixed(2)} ₼</a>
											</td>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">{(product.price).toFixed(2)} ₼</a>
											</td>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">{product.stock} {product.product_measurement?.symbol}</a>
											</td>
											<td className="text-end"  style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
													<i className="ki-duotone ki-switch fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
												</a>
												<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
													<i className="ki-duotone ki-pencil fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
												</a>
												<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
													<i className="ki-duotone ki-trash fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
													</i>
												</a>
											</td>
										</tr>
									))}


								</tbody>
								{/*-end::Table body*/}
							</table>
							{/*-end::Table*/}
						</div>
						{/*-end::Table container*/}
					</div>
					{/*-begin::Body*/}
				</div>
			</div>

    );
};

export default Products;
