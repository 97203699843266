import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import useAuth from "../../modules/auth/hooks/useAuth";

 
interface Sale {
	id: number;
	customer_name:string;
	order_number: string;
	date: string;
	status: string;
	total_amount: number;
	tax_amount: number;
	grand_total: number;
}

const Sales: React.FC = () => {
	const { auth, saveAuth } = useAuth();
	const [sales, setSales] = useState<Sale[]>([]);
	const [loading, setLoading] = useState(true);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
 
	const [newSale, setNewSale] = useState<Sale>({
		id: 0,
		customer_name:"",
		order_number: "",
		date: "",
		status: "",
		total_amount: 0,
		tax_amount: 0,
		grand_total: 0,
	});
	const dispatch = useDispatch(); // Use dispatch to send actions to Redux

 
  

	// Fetch products from the API and store them in Redux
	useEffect(() => {
		const fetchSales = async () => {
			try {
				const token = auth?.token;
				const response = await axios.get("/sale/sales-orders/?org_id=1", {
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + token,
					},
				});
				setSales(response.data); // Update local state
				setLoading(false);
			} catch (error) {
				console.error("Error fetching expenses:", error);
				setLoading(false);
			}
		};

		fetchSales();
	}, [dispatch]);


	if (loading) {
		return <p>Loading expenses...</p>; // Display a loading message
	}


	return (
		<div className="flex flex-col max-h-max max-w-max justify-center rounded-lg shadow-md p-6 dark:border dark:border-gray-800 bg-white dark:bg-zinc-900 ">

 


				<div className="card mb-5 mb-xl-8">
					{/*-begin::Header*/}
					<div className="card-header border-0 pt-5">
						<h3 className="card-title align-items-start flex-column">
							<span className="card-label fw-bold fs-3 mb-1">Satışların siyahısı</span>
							<span className="text-muted mt-1 fw-semibold fs-7">Bütün satışların siyahısı göstərilib</span>
						</h3>
						<div className="card-toolbar">
							<Link to="/app/sales/add" className="btn btn-sm btn-light-primary">
								<i className="ki-duotone ki-plus fs-2"></i>Satış əlavə et</Link>
						</div>
					</div>
					{/*-end::Header*/}
					{/*-begin::Body*/}
					<div className="card-body py-3">
						{/*-begin::Table container*/}
						<div className="table-responsive">
							{/*-begin::Table*/}
							<table className="table table-row-dashed align-middle gs-0 gy-4">
								{/*-begin::Table head*/}
								<thead>
									<tr className="fw-bold text-muted bg-light">
										<th className="ps-4 min-w-250px rounded-start">Satış Kodu</th>
										<th className="min-w-150px">Müştəri</th>
										<th className="min-w-125px">Sifariş Tarixi</th>
										<th className="min-w-125px">Məbləğ</th>
										<th className="min-w-125px">Vergi</th>
										<th className="min-w-125px">Total</th>
										<th className="min-w-100px text-end rounded-end"></th>
									</tr>
								</thead>
								{/*-end::Table head*/}
								{/*-begin::Table body*/}
								<tbody>
									{sales.map((sale) => (


										<tr key={sale.id}>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<div className="d-flex align-items-center">
													<div className="symbol symbol-40px me-5">
														<img src="/assets/file.png" className="" alt="" />
													</div>
													<div className="d-flex justify-content-start flex-column">
														<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">{sale.order_number}</a>
														<span className="text-muted fw-semibold text-muted d-block fs-7"><span className="badge badge-light-success fs-8 ">Ödənilib</span></span>
													</div>
												</div>
											</td>
											<td className="" style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">{sale.customer_name}</a>
												 
											</td>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<span className="text-muted fw-semibold text-muted d-block fs-7">{sale.total_amount}</span>
											</td>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6"></a>
												<span className="text-muted fw-semibold text-muted d-block fs-7">{sale.date}</span>
											</td>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6"></a>
												<span className="text-muted fw-semibold text-muted d-block fs-7">{sale.date}</span>
											</td>
											<td style={{paddingTop: "7px", paddingBottom: "7px"}}>
												<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6"></a>
												<span className="text-muted fw-semibold text-muted d-block fs-7">{sale.date}</span>
											</td>
											<td className="text-end" style={{paddingTop: "7px", paddingBottom: "7px"}}>
												
												<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
													<i className="ki-duotone ki-pencil fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
													</i>
												</a>
												<a href="#" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
													<i className="ki-duotone ki-trash fs-2">
														<span className="path1"></span>
														<span className="path2"></span>
														<span className="path3"></span>
														<span className="path4"></span>
														<span className="path5"></span>
													</i>
												</a>
											</td>
										</tr>
									))}


								</tbody>
								{/*-end::Table body*/}
							</table>
							{/*-end::Table*/}
						</div>
						{/*-end::Table container*/}
					</div>
					{/*-begin::Body*/}
				</div>
			</div>


	);
};

export default Sales;
