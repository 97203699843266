import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import useAuth from "../../modules/auth/hooks/useAuth";

interface SalesOrderItem {
    product: string;   // Product ID or name
    quantity: number;
    price_per_unit: number;
    total: number; // Add this property to keep track of the total for each item
}

interface SalesOrder {
    organization: number;
    customer: string;    // Customer ID or name
    order_number: string;
    date: string;      // Date in YYYY-MM-DD format
    delivery_date?: string;  // Optional delivery date
    items: SalesOrderItem[];
    description?: string;
}

const AddSalesOrder: React.FC = () => {
    const { auth } = useAuth();
    const [salesOrder, setSalesOrder] = useState<SalesOrder>({
        organization: 1,
        customer: '',
        order_number: '',
        date: '',
        items: [{ product: '', quantity: 1, price_per_unit: 0, total: 0 }]
    });
    const [customers, setCustomers] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);

    const organizationId = 1; // Assuming this is where you get the organization ID

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get(`/customer/customers?org_id=${organizationId}`, {
                    headers: {
                        'Authorization': `Bearer ${auth?.token}`,
                    },
                });
                setCustomers(response.data);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        const fetchProducts = async () => {
            try {
                const response = await axios.get(`/catalog/products?org_id=${organizationId}`, {
                    headers: {
                        'Authorization': `Bearer ${auth?.token}`,
                    },
                });
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchCustomers();
        fetchProducts();
    }, [auth?.token, organizationId]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setSalesOrder((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (selectedOption: any, { name }: any) => {
        setSalesOrder((prev) => ({ ...prev, [name]: selectedOption.value }));
    };

    const handleItemChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        const updatedItems = salesOrder.items.map((item, i) => {
            if (i === index) {
                // Parse the value to a number if it's quantity or price_per_unit, otherwise keep it as a string
                const updatedItem = {
                    ...item,
                    [name]: name === 'quantity' || name === 'price_per_unit' ? parseFloat(value) || 0 : value,
                };

                // Calculate the total for the item
                updatedItem.total = updatedItem.quantity * updatedItem.price_per_unit;

                return updatedItem;
            }
            return item;
        });

        setSalesOrder((prev) => ({ ...prev, items: updatedItems }));
    };


    const handleItemSelectChange = (index: number, selectedOption: any) => {
        const selectedProduct = products.find(product => product.id.toString() === selectedOption.value.toString());

        const updatedItems = salesOrder.items.map((item, i) => {
            if (i === index) {
                const updatedItem = {
                    ...item,
                    product: selectedOption.value,
                    price_per_unit: selectedProduct ? selectedProduct.price : 0, // Update price if the product is found
                };

                // Calculate the total for the item
                updatedItem.total = updatedItem.quantity * updatedItem.price_per_unit;

                return updatedItem;
            }
            return item;
        });

        setSalesOrder((prev) => ({ ...prev, items: updatedItems }));
    };





    const addItem = () => {
        setSalesOrder((prev) => ({
            ...prev,
            items: [...prev.items, { product: '', quantity: 1, price_per_unit: 0, total: 0 }]
        }));
    };

    const removeItem = (index: number) => {
        const updatedItems = salesOrder.items.filter((_, i) => i !== index);
        setSalesOrder((prev) => ({ ...prev, items: updatedItems }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // Your form submission logic here
    };

    // Convert customers and products to the format react-select expects
    const customerOptions = customers.map((customer) => ({
        value: customer.id,
        label: customer.name,
    }));

    const productOptions = products.map((product) => ({
        value: product.id,
        label: product.name,
    }));

    return (
        <div className="d-flex flex-column flex-column-fluid">
            <div id="kt_app_toolbar" className="app-toolbar " style={{padding:"20px 0 0 0"}}>
                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">Satış Əlavə Et</h1>
                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <li className="breadcrumb-item text-muted">
                                <a href="/metronic8/demo1/index.html" className="text-muted text-hover-primary">Satışların siyahısı</a>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="bullet bg-gray-500 w-5px h-2px"></span>
                            </li>
                            <li className="breadcrumb-item text-muted">Yeni Satış</li>
                        </ul>
                    </div>

                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid" style={{paddingTop: "15px"}}>
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="d-flex flex-column flex-lg-row">
                        <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                            <div className="card">
                                <div className="card-body" style={{padding:"25px 40px 40px 40px"}}>
                                    <form action="" onSubmit={handleSubmit}>
                                        <div className="d-flex flex-column align-items-start flex-xxl-row">

                                            <div className="d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4">
                                                <span className="fs-2x fw-bold text-gray-800" style={{ marginRight: "10px" }}>Satış No #</span>
                                                <input type='text' className="form-control form-control-solid" placeholder="Sifariş no ..."
                                                    name="order_number"
                                                    value={salesOrder.order_number}
                                                    onChange={handleInputChange}
                                                    required />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end flex-equal order-3 fw-row">

                                            </div>
                                        </div>
                                        <div className="separator separator-dashed my-10"></div>
                                        <div className="mb-0">






                                            <div className="row gx-10 mb-5">
                                                <div className='col-lg-6'>

                                                    <label className="form-label fw-bold fs-6 text-gray-700">Müştəri Seç</label>

                                                    <Select
                                                        name="customer"
                                                        className=" form-select-solid "
                                                        options={customerOptions}
                                                        value={customerOptions.find(option => option.value === salesOrder.customer)}
                                                        onChange={handleSelectChange}
                                                        placeholder="Müştəri Seçin"
                                                        isSearchable
                                                        required
                                                    />

                                                    <div className="mb-5 mt-8">
                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5">
                                                            <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Sifariş artıq anbardadır</span>
                                                            <input className="form-check-input" type="checkbox" value="" />
                                                        </label>
                                                        <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5">
                                                            <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Əlavə xərclər mövcuddur</span>
                                                            <input className="form-check-input" type="checkbox" value="" />
                                                        </label>

                                                    </div>


                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='col-lg-6'>
                                                        <div className="d-flex align-items-center flex-equal fw-row me-4 order-2">
                                                            <div className="fs-6 fw-bold text-gray-700 text-nowrap">Sifariş Tarixi:</div>
                                                            <div className="position-relative d-flex align-items-center w-150px">
                                                                <input className="form-control form-control-transparent fw-bold pe-4 flatpickr-input" placeholder="Tarix"
                                                                    type="date"
                                                                    name="date"
                                                                    value={salesOrder.date}
                                                                    onChange={handleInputChange}
                                                                    required />
                                                                <i className="ki-duotone ki-down fs-4 position-absolute ms-4 end-0"></i>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex align-items-center flex-equal fw-row me-4 order-2">
                                                            <div className="fs-6 fw-bold text-gray-700 text-nowrap">Çatdırılma Tarixi:</div>
                                                            <div className="position-relative d-flex align-items-center w-150px">
                                                                <input className="form-control form-control-transparent fw-bold pe-4 flatpickr-input" placeholder="Tarix"
                                                                    type="date"
                                                                    name="delivery_date"
                                                                    value={salesOrder.delivery_date}
                                                                    onChange={handleInputChange}
                                                                    required />
                                                                <i className="ki-duotone ki-down fs-4 position-absolute ms-4 end-0"></i>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                           

                                           
                                            <div className="mb-0">


                                            </div>
                                             
                                            <div className="separator separator-dashed my-10"></div>
                                            <div className="table-responsive mb-10">
                                                <table className="table g-5 gs-0 mb-0 fw-bold text-gray-700" data-kt-element="items">
                                                    <thead>
                                                        <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                                                            <th className=" w-300px">Məhsul</th>
                                                            <th className=" w-100px text-center">MİQDAR</th>
                                                            <th className=" w-100px text-center">QİYMƏT</th>
                                                            <th className=" w-100px text-center">ENDİRİM</th>
                                                            <th className=" w-100px text-center">VERGİ</th>
                                                            <th className=" w-100px text-end">Total</th>
                                                            <th className=" w-5px text-center">#</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {salesOrder.items.map((item, index) => (
                                                            <tr className="border-bottom border-bottom-dashed" data-kt-element="item">
                                                                <td className="pe-7" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                                    <Select
                                                                        name="product"
                                                                        className="form-control-solid mb-2"
                                                                        options={productOptions}
                                                                        value={productOptions.find(option => option.value === item.product)}
                                                                        onChange={(selectedOption) => handleItemSelectChange(index, selectedOption)}
                                                                        placeholder="Məhsul Seçin"
                                                                        isSearchable
                                                                        required
                                                                       
                                                                    />
                                                                </td>
                                                                <td className=" text-center" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                                    <input className="form-control form-control-solid text-end"
                                                                        type="number"
                                                                        name="quantity"
                                                                        placeholder="Miqdar"
                                                                        value={item.quantity}
                                                                        onChange={(e) => handleItemChange(index, e)}
                                                                        required
                                                                        style={{padding: "7px 11px",color: "#4b5675"}}
                                                                         
                                                                    />
                                                                </td>
                                                                <td style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                                    <input className="form-control form-control-solid text-end"
                                                                        type="number"
                                                                        name="price_per_unit"
                                                                        placeholder="Price per unit"
                                                                        value={item.price_per_unit}
                                                                        onChange={(e) => handleItemChange(index, e)}
                                                                        required 
                                                                        style={{padding: "7px 11px",color: "#4b5675"}}/>
                                                                </td>
                                                                <td style={{paddingTop: "5px", paddingBottom: "5px"}}> 
                                                                    <input className="form-control form-control-solid text-end"
                                                                        type="number"
                                                                        name="price_per_unit"
                                                                        placeholder="Price per unit"
                                                                        value={item.price_per_unit}
                                                                        onChange={(e) => handleItemChange(index, e)}
                                                                        required 
                                                                        style={{padding: "7px 11px",color: "#4b5675"}}/>
                                                                </td>
                                                                <td className="pt-5 text-center text-nowrap" style={{paddingTop: "5px", paddingBottom: "5px"}}>₼ <span data-kt-element="total">0</span></td>
                                                                <td className="pt-5 text-end text-nowrap" style={{paddingTop: "5px", paddingBottom: "5px"}}>₼ <span data-kt-element="total">{item.total.toFixed(2)}</span></td>
                                                                <td className="pt-2 text-end" style={{paddingTop: "5px", paddingBottom: "5px"}}>
                                                                    <button type="button" className="btn btn-sm btn-icon btn-active-color-primary" data-kt-element="remove-item" onClick={() => removeItem(index)}>
                                                                        <i className="ki-duotone ki-trash fs-3"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <button type='button' onClick={addItem} className="btn btn-sm btn-flex btn-primary align-self-center" style={{padding:"0 10px 0 3px"}}><i className="ki-outline ki-plus-square fs-3"></i>Məhsul Əlavə Et</button>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr className="border-top border-top-dashed align-top fs-6 fw-bold text-gray-700">
                                                            <th  colSpan={4} className="text-primary">
                                                                 
                                                            </th>
                                                            <th colSpan={2} className="border-bottom border-bottom-dashed ps-0">
                                                                <div className="d-flex flex-column align-items-start">
                                                                    <div className="fs-5">Cəmi Məbləğ</div>
                                                                    <div className="fs-5">Vergi</div>
                                                                    <div className="fs-5">Endirim</div>
                                                                </div>
                                                            </th>
                                                            <th colSpan={2} className="border-bottom border-bottom-dashed text-end">
                                                                <div className="fs-5">₼ <span data-kt-element="sub-total">0.00</span></div>
                                                               <div className="fs-5"> ₼ <span data-kt-element="sub-total">0.00</span></div>
                                                               <div className="fs-5"> ₼ <span data-kt-element="sub-total">0.00</span></div>
                                                                </th>
                                                        </tr>
                                                        <tr className="align-top fw-bold text-gray-700">
                                                            <th colSpan={4}></th>
                                                            <th colSpan={2} className="fs-4 ps-0">Yekun Məbləğ</th>
                                                            <th colSpan={2} className="text-end fs-4 text-nowrap">₼ <span data-kt-element="grand-total">0.00</span></th>
                                                        </tr>
                                                    </tfoot>

                                                </table>
                                            <div className="separator separator-dashed my-10"></div>

                                                <div className="mb-5">
                                                <label className="form-label fs-6 fw-bold text-gray-700">Qeyd</label>
                                                <textarea className="form-control form-control-solid" rows={2} placeholder="Əlavə Qeyd ..."
                                                    name="description"
                                                    value={salesOrder.description || ''}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                                <div style={{float:"right"}}>
                                                <button type="button" className="btn btn-primary " id="kt_invoice_submit_button" style={{width:"150px",marginRight:"10px"}}>
                                                    <i className="ki-duotone ki-triangle fs-3"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i> Geri
                                                </button>
                                                <button type="submit" className="btn btn-success " id="kt_invoice_submit_button" style={{width:"250px"}}>
                                                    <i className="ki-duotone ki-triangle fs-3"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i> Sifarişi Əlavə Et
                                                </button>
                                                </div>
                                                
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSalesOrder;
