import { createContext } from "react";
import { AuthToken, User } from "../types/authTypes";

export type AuthContextProps = {
	auth: AuthToken | undefined;
	saveAuth: (auth: AuthToken | undefined) => void;
	currentUser: User | undefined;
	setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>;
	logout: () => void;
};

export const initAuthContextPropsState: AuthContextProps = {
	auth: undefined,
	saveAuth: () => {},
	currentUser: undefined,
	setCurrentUser: () => {},
	logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

export default AuthContext;
