import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Product {
	id: number;
	name: string;
	code: string;
	product_measurement?: {
		name: string;
	};
	stock: number;
	p_cost: number;
	p_price: number;
	tax?: {
		percent: number;
	};
	images: { image: string }[];
}

interface ProductState {
	products: Product[];
}

const initialState: ProductState = {
	products: [],
};

const productSlice = createSlice({
	name: "products",
	initialState,
	reducers: {
		setProducts(state, action: PayloadAction<Product[]>) {
			state.products = action.payload;
		},
		addProduct(state, action: PayloadAction<Product>) {
			state.products.push(action.payload);
		},
		updateProduct(state, action: PayloadAction<Product>) {
			const index = state.products.findIndex(
				(product) => product.id === action.payload.id
			);
			if (index !== -1) {
				state.products[index] = action.payload;
			}
		},
		removeProduct(state, action: PayloadAction<number>) {
			state.products = state.products.filter(
				(product) => product.id !== action.payload
			);
		},
	},
});

export const { setProducts, addProduct, updateProduct, removeProduct } =
	productSlice.actions;
export default productSlice.reducer;
