import { Route, Routes } from "react-router-dom";
import { Login } from "../components/Login";
import { AuthLayout } from "../layout/AuthLayout";

const AuthPage = () => (
	<Routes>
		{/* Используем AuthLayout как обертку для страниц аутентификации */}
		<Route element={<AuthLayout />}>
			{/* Маршрут для страницы входа */}
			<Route path="login" element={<Login />} />
			{/* Маршрут по умолчанию, который также перенаправляет на страницу входа */}
			<Route index element={<Login />} />
		</Route>
	</Routes>
);

export { AuthPage };
