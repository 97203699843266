import React, { useState, useEffect, FC } from "react";
import LayoutSplashScreen from "../components/LayoutSplashScreen";
import useAuth from "../hooks/useAuth";
import { getUserByToken } from "../api/authRequests";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/slices/authSlice";

type WithChildren<T = {}> = T & { children?: React.ReactNode };

const AuthInit: FC<WithChildren> = ({ children }) => {
	const { auth, logout } = useAuth();
	const dispatch = useDispatch();
	const [showSplashScreen, setShowSplashScreen] = useState(true);

	useEffect(() => {
		const requestUser = async (token: string) => {
			try {
				const user = await getUserByToken(token);
				if (user) {
					dispatch(setUser(user)); // Saving user in Redux
				}
			} catch (error) {
				console.error(error);
				logout(); // On error logout
			} finally {
				setShowSplashScreen(false);
			}
		};

		if (auth && auth.token) {
			requestUser(auth.token);
		} else {
			logout();
			setShowSplashScreen(false);
		}
	}, [auth, dispatch, logout]);

	return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export default AuthInit;
