import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setProducts, addProduct } from "../../redux/slices/productSlice"; // Import actions from the product slice

interface Product {
	id: number;
	name: string;
	code: string;
	product_measurement?: {
		name: string;
	};
	stock: number;
	p_cost: number;
	p_price: number;
	tax?: {
		percent: number;
	};
	images: { image: string }[];
}

const Products: React.FC = () => {
	const [products, setLocalProducts] = useState<Product[]>([]);
	const [loading, setLoading] = useState(true);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [open, setOpen] = useState(false);
	const [newProduct, setNewProduct] = useState<Product>({
		id: 0,
		name: "",
		code: "",
		product_measurement: { name: "" },
		stock: 0,
		p_cost: 0,
		p_price: 0,
		tax: { percent: 0 },
		images: [],
	});
	const dispatch = useDispatch(); // Use dispatch to send actions to Redux

	// Fetch products from the API and store them in Redux
	useEffect(() => {
		const fetchProducts = async () => {
			try {
				const response = await axios.get("/catalog/product/list/");
				setLocalProducts(response.data); // Update local state
				dispatch(setProducts(response.data)); // Save data to Redux
				setLoading(false);
			} catch (error) {
				console.error("Error fetching products:", error);
				setLoading(false);
			}
		};

		fetchProducts();
	}, [dispatch]);

 

	// Open the modal dialog
	const handleClickOpen = () => {
		setOpen(true);
	};

	// Close the modal dialog
	const handleClose = () => {
		setOpen(false);
	};

	// Handle input changes in the form
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewProduct({ ...newProduct, [event.target.name]: event.target.value });
	};

	// Handle form submission
	const handleSubmit = async () => {
		try {
			const response = await axios.post("/catalog/product/create/", newProduct);
			dispatch(addProduct(response.data)); // Add new product to Redux
			handleClose();
			// Fetch the updated list of products after adding a new one
			const updatedResponse = await axios.get("/catalog/product/list/");
			setLocalProducts(updatedResponse.data);
		} catch (error) {
			console.error("Error adding product:", error);
		}
	};

	return (
		
		
					<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
						{/*begin::Content wrapper*/}
						<div className="d-flex flex-column flex-column-fluid">
							{/*begin::Content*/}
							<div id="kt_app_content" className="app-content flex-column-fluid">
								{/*begin::Content container*/}
								<div id="kt_app_content_container" className="app-container container-fluid">
									{/*begin::Row*/}
									<div className="row gy-5 g-xl-10">
										{/*begin::Col*/}
										<div className="col-sm-6 col-xl-2 mb-xl-10">
											{/*begin::Card widget 2*/}
											<div className="card h-lg-100">
												{/*begin::Body*/}
												<div className="card-body d-flex justify-content-between align-items-start flex-column">
													{/*begin::Icon*/}
													<div className="m-0">
														<i className="ki-outline ki-compass fs-2hx text-gray-600"></i>
													</div>
													{/*end::Icon*/}
													{/*begin::Section*/}
													<div className="d-flex flex-column my-7">
														{/*begin::Number*/}
														<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">327</span>
														{/*end::Number*/}
														{/*begin::Follower*/}
														<div className="m-0">
															<span className="fw-semibold fs-6 text-gray-500">Projects</span>
														</div>
														{/*end::Follower*/}
													</div>
													{/*end::Section*/}
													{/*begin::Badge*/}
													<span className="badge badge-light-success fs-base">
													<i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>2.1%</span>
													{/*end::Badge*/}
												</div>
												{/*end::Body*/}
											</div>
											{/*end::Card widget 2*/}
										</div>
										{/*end::Col*/}
										{/*begin::Col*/}
										<div className="col-sm-6 col-xl-2 mb-xl-10">
											{/*begin::Card widget 2*/}
											<div className="card h-lg-100">
												{/*begin::Body*/}
												<div className="card-body d-flex justify-content-between align-items-start flex-column">
													{/*begin::Icon*/}
													<div className="m-0">
														<i className="ki-outline ki-chart-simple fs-2hx text-gray-600"></i>
													</div>
													{/*end::Icon*/}
													{/*begin::Section*/}
													<div className="d-flex flex-column my-7">
														{/*begin::Number*/}
														<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">27,5M</span>
														{/*end::Number*/}
														{/*begin::Follower*/}
														<div className="m-0">
															<span className="fw-semibold fs-6 text-gray-500">Stock Qty</span>
														</div>
														{/*end::Follower*/}
													</div>
													{/*end::Section*/}
													{/*begin::Badge*/}
													<span className="badge badge-light-success fs-base">
													<i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>2.1%</span>
													{/*end::Badge*/}
												</div>
												{/*end::Body*/}
											</div>
											{/*end::Card widget 2*/}
										</div>
										{/*end::Col*/}
										{/*begin::Col*/}
										<div className="col-sm-6 col-xl-2 mb-xl-10">
											{/*begin::Card widget 2*/}
											<div className="card h-lg-100">
												{/*begin::Body*/}
												<div className="card-body d-flex justify-content-between align-items-start flex-column">
													{/*begin::Icon*/}
													<div className="m-0">
														<i className="ki-outline ki-abstract-39 fs-2hx text-gray-600"></i>
													</div>
													{/*end::Icon*/}
													{/*begin::Section*/}
													<div className="d-flex flex-column my-7">
														{/*begin::Number*/}
														<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">149M</span>
														{/*end::Number*/}
														{/*begin::Follower*/}
														<div className="m-0">
															<span className="fw-semibold fs-6 text-gray-500">Stock Value</span>
														</div>
														{/*end::Follower*/}
													</div>
													{/*end::Section*/}
													{/*begin::Badge*/}
													<span className="badge badge-light-danger fs-base">
													<i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1"></i>0.47%</span>
													{/*end::Badge*/}
												</div>
												{/*end::Body*/}
											</div>
											{/*end::Card widget 2*/}
										</div>
										{/*end::Col*/}
										{/*begin::Col*/}
										<div className="col-sm-6 col-xl-2 mb-xl-10">
											{/*begin::Card widget 2*/}
											<div className="card h-lg-100">
												{/*begin::Body*/}
												<div className="card-body d-flex justify-content-between align-items-start flex-column">
													{/*begin::Icon*/}
													<div className="m-0">
														<i className="ki-outline ki-map fs-2hx text-gray-600"></i>
													</div>
													{/*end::Icon*/}
													{/*begin::Section*/}
													<div className="d-flex flex-column my-7">
														{/*begin::Number*/}
														<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">89M</span>
														{/*end::Number*/}
														{/*begin::Follower*/}
														<div className="m-0">
															<span className="fw-semibold fs-6 text-gray-500">C APEX</span>
														</div>
														{/*end::Follower*/}
													</div>
													{/*end::Section*/}
													{/*begin::Badge*/}
													<span className="badge badge-light-success fs-base">
													<i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>2.1%</span>
													{/*end::Badge*/}
												</div>
												{/*end::Body*/}
											</div>
											{/*end::Card widget 2*/}
										</div>
										{/*end::Col*/}
										{/*begin::Col*/}
										<div className="col-sm-6 col-xl-2 mb-5 mb-xl-10">
											{/*begin::Card widget 2*/}
											<div className="card h-lg-100">
												{/*begin::Body*/}
												<div className="card-body d-flex justify-content-between align-items-start flex-column">
													{/*begin::Icon*/}
													<div className="m-0">
														<i className="ki-outline ki-abstract-35 fs-2hx text-gray-600"></i>
													</div>
													{/*end::Icon*/}
													{/*begin::Section*/}
													<div className="d-flex flex-column my-7">
														{/*begin::Number*/}
														<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">72.4%</span>
														{/*end::Number*/}
														{/*begin::Follower*/}
														<div className="m-0">
															<span className="fw-semibold fs-6 text-gray-500">OPEX</span>
														</div>
														{/*end::Follower*/}
													</div>
													{/*end::Section*/}
													{/*begin::Badge*/}
													<span className="badge badge-light-danger fs-base">
													<i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1"></i>0.647%</span>
													{/*end::Badge*/}
												</div>
												{/*end::Body*/}
											</div>
											{/*end::Card widget 2*/}
										</div>
										{/*end::Col*/}
										{/*begin::Col*/}
										<div className="col-sm-6 col-xl-2 mb-5 mb-xl-10">
											{/*begin::Card widget 2*/}
											<div className="card h-lg-100">
												{/*begin::Body*/}
												<div className="card-body d-flex justify-content-between align-items-start flex-column">
													{/*begin::Icon*/}
													<div className="m-0">
														<i className="ki-outline ki-abstract-26 fs-2hx text-gray-600"></i>
													</div>
													{/*end::Icon*/}
													{/*begin::Section*/}
													<div className="d-flex flex-column my-7">
														{/*begin::Number*/}
														<span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">106M</span>
														{/*end::Number*/}
														{/*begin::Follower*/}
														<div className="m-0">
															<span className="fw-semibold fs-6 text-gray-500">Saving</span>
														</div>
														{/*end::Follower*/}
													</div>
													{/*end::Section*/}
													{/*begin::Badge*/}
													<span className="badge badge-light-success fs-base">
													<i className="ki-outline ki-arrow-up fs-5 text-success ms-n1"></i>2.1%</span>
													{/*end::Badge*/}
												</div>
												{/*end::Body*/}
											</div>
											{/*end::Card widget 2*/}
										</div>
										{/*end::Col*/}
									</div>
									{/*end::Row*/}
									{/*begin::Row*/}
									<div className="row gy-5 g-xl-10">
										{/*begin::Col*/}
										<div className="col-xl-4 mb-xl-10">
											{/*begin::List widget 17*/}
											<div className="card card-flush h-xl-100">
												{/*begin::Header*/}
												<div className="card-header pt-7">
													{/*begin::Title*/}
													<h3 className="card-title align-items-start flex-column">
														<span className="card-label fw-bold text-gray-800">Popular Products</span>
														<span className="text-gray-500 mt-1 fw-semibold fs-6">8k social visitors</span>
													</h3>
													{/*end::Title*/}
													{/*begin::Toolbar*/}
													<div className="card-toolbar">
														<a href="apps/ecommerce/catalog/add-product.html" className="btn btn-sm btn-light">Add Product</a>
													</div>
													{/*end::Toolbar*/}
												</div>
												{/*end::Header*/}
												{/*begin::Body*/}
												<div className="card-body pt-0">
													{/*begin::Content*/}
													<div className="d-flex flex-stack my-5">
														<span className="text-gray-500 fs-7 fw-bold">ITEM</span>
														<span className="text-gray-500 fw-bold fs-7">ITEM PRICE</span>
													</div>
													{/*end::Content*/}
													{/*begin::Item*/}
													<div className="d-flex flex-stack">
														{/*begin::Wrapper*/}
														<div className="d-flex align-items-center me-3">
															{/*begin::Icon*/}
															<img src="/assets/media/stock/ecommerce/14.png" className="me-4 w-50px" alt="" />
															{/*end::Icon*/}
															{/*begin::Section*/}
															<div className="flex-grow-1">
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fs-5 fw-bold lh-0">Fjallraven</a>
																<span className="text-gray-500 fw-semibold d-block fs-7">Item: #XDG-6437</span>
															</div>
															{/*end::Section*/}
														</div>
														{/*end::Wrapper*/}
														{/*begin::Value*/}
														<span className="text-gray-800 fw-bold fs-6">$ 72.00</span>
														{/*end::Value*/}
													</div>
													{/*end::Item*/}
													{/*begin::Separator*/}
													<div className="separator separator-dashed my-4"></div>
													{/*end::Separator*/}
													{/*begin::Item*/}
													<div className="d-flex flex-stack">
														{/*begin::Wrapper*/}
														<div className="d-flex align-items-center me-3">
															{/*begin::Icon*/}
															<img src="/assets/media/stock/ecommerce/13.png" className="me-4 w-50px" alt="" />
															{/*end::Icon*/}
															{/*begin::Section*/}
															<div className="flex-grow-1">
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fs-5 fw-bold lh-0">Nike AirMax</a>
																<span className="text-gray-500 fw-semibold d-block fs-7">Item: #XDG-1836</span>
															</div>
															{/*end::Section*/}
														</div>
														{/*end::Wrapper*/}
														{/*begin::Value*/}
														<span className="text-gray-800 fw-bold fs-6">$ 45.00</span>
														{/*end::Value*/}
													</div>
													{/*end::Item*/}
													{/*begin::Separator*/}
													<div className="separator separator-dashed my-4"></div>
													{/*end::Separator*/}
													{/*begin::Item*/}
													<div className="d-flex flex-stack">
														{/*begin::Wrapper*/}
														<div className="d-flex align-items-center me-3">
															{/*begin::Icon*/}
															<img src="/assets/media/stock/ecommerce/41.png" className="me-4 w-50px" alt="" />
															{/*end::Icon*/}
															{/*begin::Section*/}
															<div className="flex-grow-1">
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fs-5 fw-bold lh-0">Bose QC 35</a>
																<span className="text-gray-500 fw-semibold d-block fs-7">Item: #XDG-6254</span>
															</div>
															{/*end::Section*/}
														</div>
														{/*end::Wrapper*/}
														{/*begin::Value*/}
														<span className="text-gray-800 fw-bold fs-6">$ 168.00</span>
														{/*end::Value*/}
													</div>
													{/*end::Item*/}
													{/*begin::Separator*/}
													<div className="separator separator-dashed my-4"></div>
													{/*end::Separator*/}
													{/*begin::Item*/}
													<div className="d-flex flex-stack">
														{/*begin::Wrapper*/}
														<div className="d-flex align-items-center me-3">
															{/*begin::Icon*/}
															<img src="/assets/media/stock/ecommerce/53.png" className="me-4 w-50px" alt="" />
															{/*end::Icon*/}
															{/*begin::Section*/}
															<div className="flex-grow-1">
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fs-5 fw-bold lh-0">Greeny</a>
																<span className="text-gray-500 fw-semibold d-block fs-7">Item: #XDG-1746</span>
															</div>
															{/*end::Section*/}
														</div>
														{/*end::Wrapper*/}
														{/*begin::Value*/}
														<span className="text-gray-800 fw-bold fs-6">$ 14.50</span>
														{/*end::Value*/}
													</div>
													{/*end::Item*/}
													{/*begin::Separator*/}
													<div className="separator separator-dashed my-4"></div>
													{/*end::Separator*/}
													{/*begin::Item*/}
													<div className="d-flex flex-stack">
														{/*begin::Wrapper*/}
														<div className="d-flex align-items-center me-3">
															{/*begin::Icon*/}
															<img src="/assets/media/stock/ecommerce/71.png" className="me-4 w-50px" alt="" />
															{/*end::Icon*/}
															{/*begin::Section*/}
															<div className="flex-grow-1">
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fs-5 fw-bold lh-0">Apple Watches</a>
																<span className="text-gray-500 fw-semibold d-block fs-7">Item: #XDG-6245</span>
															</div>
															{/*end::Section*/}
														</div>
														{/*end::Wrapper*/}
														{/*begin::Value*/}
														<span className="text-gray-800 fw-bold fs-6">$ 362.00</span>
														{/*end::Value*/}
													</div>
													{/*end::Item*/}
													{/*begin::Separator*/}
													<div className="separator separator-dashed my-4"></div>
													{/*end::Separator*/}
													{/*begin::Item*/}
													<div className="d-flex flex-stack">
														{/*begin::Wrapper*/}
														<div className="d-flex align-items-center me-3">
															{/*begin::Icon*/}
															<img src="/assets/media/stock/ecommerce/194.png" className="me-4 w-50px" alt="" />
															{/*end::Icon*/}
															{/*begin::Section*/}
															<div className="flex-grow-1">
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fs-5 fw-bold lh-0">Friendly Robot</a>
																<span className="text-gray-500 fw-semibold d-block fs-7">Item: #XDG-2347</span>
															</div>
															{/*end::Section*/}
														</div>
														{/*end::Wrapper*/}
														{/*begin::Value*/}
														<span className="text-gray-800 fw-bold fs-6">$ 48.00</span>
														{/*end::Value*/}
													</div>
													{/*end::Item*/}
												</div>
												{/*end::Body*/}
											</div>
											{/*end::List widget 17*/}
										</div>
										{/*end::Col*/}
										{/*begin::Col*/}
										<div className="col-xl-8 mb-5 mb-xl-10">
											{/*begin::Table widget 6*/}
											<div className="card card-flush h-xl-100">
												{/*begin::Header*/}
												<div className="card-header pt-7">
													{/*begin::Title*/}
													<h3 className="card-title align-items-start flex-column">
														<span className="card-label fw-bold text-gray-800">Leading Agents by Category</span>
														<span className="text-gray-500 mt-1 fw-semibold fs-6">Total 424,567 deliveries</span>
													</h3>
													{/*end::Title*/}
													{/*begin::Toolbar*/}
													<div className="card-toolbar">
														<a href="apps/ecommerce/catalog/add-product.html" className="btn btn-sm btn-light">Add Product</a>
													</div>
													{/*end::Toolbar*/}
												</div>
												{/*end::Header*/}
												{/*begin::Body*/}
												<div className="card-body">
													{/*begin::Nav*/}
													<ul className="nav nav-pills nav-pills-custom mb-3">
														{/*begin::Item*/}
														<li className="nav-item mb-3 me-3 me-lg-6">
															{/*begin::Link*/}
															<a className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active" data-bs-toggle="pill" href="#kt_stats_widget_6_tab_1">
																{/*begin::Icon*/}
																<div className="nav-icon mb-3">
																	<i className="ki-outline ki-truck fs-1"></i>
																</div>
																{/*end::Icon*/}
																{/*begin::Title*/}
																<span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Van</span>
																{/*end::Title*/}
																{/*begin::Bullet*/}
																<span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
																{/*end::Bullet*/}
															</a>
															{/*end::Link*/}
														</li>
														{/*end::Item*/}
														{/*begin::Item*/}
														<li className="nav-item mb-3 me-3 me-lg-6">
															{/*begin::Link*/}
															<a className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2" data-bs-toggle="pill" href="#kt_stats_widget_6_tab_2">
																{/*begin::Icon*/}
																<div className="nav-icon mb-3">
																	<i className="ki-outline ki-bus fs-1"></i>
																</div>
																{/*end::Icon*/}
																{/*begin::Title*/}
																<span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Train</span>
																{/*end::Title*/}
																{/*begin::Bullet*/}
																<span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
																{/*end::Bullet*/}
															</a>
															{/*end::Link*/}
														</li>
														{/*end::Item*/}
														{/*begin::Item*/}
														<li className="nav-item mb-3 me-3 me-lg-6">
															{/*begin::Link*/}
															<a className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2" data-bs-toggle="pill" href="#kt_stats_widget_6_tab_3">
																{/*begin::Icon*/}
																<div className="nav-icon mb-3">
																	<i className="ki-outline ki-logistic fs-1"></i>
																</div>
																{/*end::Icon*/}
																{/*begin::Title*/}
																<span className="nav-text text-gray-800 fw-bold fs-6 lh-1">Drone</span>
																{/*end::Title*/}
																{/*begin::Bullet*/}
																<span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
																{/*end::Bullet*/}
															</a>
															{/*end::Link*/}
														</li>
														{/*end::Item*/}
													</ul>
													{/*end::Nav*/}
													{/*begin::Tab Content*/}
													<div className="tab-content">
														{/*begin::Tap pane*/}
														<div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
															{/*begin::Table container*/}
															<div className="table-responsive">
																{/*begin::Table*/}
																<table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
																	{/*begin::Table head*/}
																	<thead>
																		<tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
																			<th className="p-0 w-200px w-xxl-450px"></th>
																			<th className="p-0 min-w-150px"></th>
																			<th className="p-0 min-w-150px"></th>
																			<th className="p-0 min-w-190px"></th>
																			<th className="p-0 w-50px"></th>
																		</tr>
																	</thead>
																	{/*end::Table head*/}
																	{/*begin::Table body*/}
																	<tbody>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-1.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Brooklyn Simmons</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">1,240</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$5,400</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-2.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Annette Black</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">6,074</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$174,074</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-12.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Esther Howard</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">357</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$2,737</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-11.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Guy Hawkins</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">2,954</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$59,634</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-13.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Marvin McKinney</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">822</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$19,842</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																	</tbody>
																	{/*end::Table body*/}
																</table>
															</div>
															{/*end::Table*/}
														</div>
														{/*end::Tap pane*/}
														{/*begin::Tap pane*/}
														<div className="tab-pane fade" id="kt_stats_widget_6_tab_2">
															{/*begin::Table container*/}
															<div className="table-responsive">
																{/*begin::Table*/}
																<table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
																	{/*begin::Table head*/}
																	<thead>
																		<tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
																			<th className="p-0 w-200px w-xxl-450px"></th>
																			<th className="p-0 min-w-150px"></th>
																			<th className="p-0 min-w-150px"></th>
																			<th className="p-0 min-w-190px"></th>
																			<th className="p-0 w-50px"></th>
																		</tr>
																	</thead>
																	{/*end::Table head*/}
																	{/*begin::Table body*/}
																	<tbody>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-11.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Guy Hawkins</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">2,954</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$59,634</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-13.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Marvin McKinney</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">822</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$19,842</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-1.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Brooklyn Simmons</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">1,240</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$5,400</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-2.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Annette Black</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">6,074</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$174,074</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-12.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Esther Howard</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">357</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$2,737</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																	</tbody>
																	{/*end::Table body*/}
																</table>
															</div>
															{/*end::Table*/}
														</div>
														{/*end::Tap pane*/}
														{/*begin::Tap pane*/}
														<div className="tab-pane fade" id="kt_stats_widget_6_tab_3">
															{/*begin::Table container*/}
															<div className="table-responsive">
																{/*begin::Table*/}
																<table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
																	{/*begin::Table head*/}
																	<thead>
																		<tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
																			<th className="p-0 w-200px w-xxl-450px"></th>
																			<th className="p-0 min-w-150px"></th>
																			<th className="p-0 min-w-150px"></th>
																			<th className="p-0 min-w-190px"></th>
																			<th className="p-0 w-50px"></th>
																		</tr>
																	</thead>
																	{/*end::Table head*/}
																	{/*begin::Table body*/}
																	<tbody>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-1.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Brooklyn Simmons</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">1,240</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$5,400</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-11.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Guy Hawkins</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">2,954</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$59,634</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-13.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Marvin McKinney</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">822</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$19,842</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-12.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Esther Howard</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">357</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$2,737</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<div className="d-flex align-items-center">
																					<div className="symbol symbol-40px me-3">
																						<img src="/assets/media/avatars/300-2.jpg" className="" alt="" />
																					</div>
																					<div className="d-flex justify-content-start flex-column">
																						<a href="#" className="text-gray-900 fw-bold text-hover-primary mb-1 fs-6">Annette Black</a>
																						<span className="text-muted fw-semibold d-block fs-7">Zuid Area</span>
																					</div>
																				</div>
																			</td>
																			<td>
																				<span className="text-gray-800 fw-bold d-block mb-1 fs-6">6,074</span>
																				<span className="fw-semibold text-gray-500 d-block">Deliveries</span>
																			</td>
																			<td>
																				<a href="#" className="text-gray-900 fw-bold text-hover-primary d-block mb-1 fs-6">$174,074</a>
																				<span className="text-muted fw-semibold d-block fs-7">Earnings</span>
																			</td>
																			<td>
																				<div className="rating">
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																					<div className="rating-label checked">
																						<i className="ki-solid ki-star fs-6"></i>
																					</div>
																				</div>
																				<span className="text-muted fw-semibold d-block fs-7 mt-1">Rating</span>
																			</td>
																			<td className="text-end">
																				<a href="#" className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
																					<i className="ki-outline ki-black-right fs-2 text-gray-500"></i>
																				</a>
																			</td>
																		</tr>
																	</tbody>
																	{/*end::Table body*/}
																</table>
															</div>
															{/*end::Table*/}
														</div>
														{/*end::Tap pane*/}
													</div>
													{/*end::Tab Content*/}
												</div>
												{/*end: Card Body*/}
											</div>
											{/*end::Table widget 6*/}
										</div>
										{/*end::Col*/}
									</div>
									{/*end::Row*/}
									{/*begin::Row*/}
									<div className="row g-5 g-xl-10 mb-5 mb-xl-10">
										{/*begin::Col*/}
										<div className="col-xxl-4">
											{/*begin::Engage widget 1*/}
											<div className="card h-md-100" dir="ltr">
												{/*begin::Body*/}
												<div className="card-body d-flex flex-column flex-center">
													{/*begin::Heading*/}
													<div className="mb-2">
														{/*begin::Title*/}
														<h1 className="fw-semibold text-gray-800 text-center lh-lg">Try out our 
														<br />new 
														<span className="fw-bolder">Invoice Manager</span></h1>
														{/*end::Title*/}
														{/*begin::Illustration*/}
														<div className="py-10 text-center">
															<img src="/assets/media/svg/illustrations/easy/2.svg" className="theme-light-show w-200px" alt="" />
															<img src="/assets/media/svg/illustrations/easy/2-dark.svg" className="theme-dark-show w-200px" alt="" />
														</div>
														{/*end::Illustration*/}
													</div>
													{/*end::Heading*/}
													{/*begin::Links*/}
													<div className="text-center mb-1">
														{/*begin::Link*/}
														<a className="btn btn-sm btn-primary me-2" data-bs-target="#kt_modal_new_address" data-bs-toggle="modal">Try Now</a>
														{/*end::Link*/}
														{/*begin::Link*/}
														<a className="btn btn-sm btn-light" href="apps/user-management/users/view.html">Learn More</a>
														{/*end::Link*/}
													</div>
													{/*end::Links*/}
												</div>
												{/*end::Body*/}
											</div>
											{/*end::Engage widget 1*/}
										</div>
										{/*end::Col*/}
										{/*begin::Col*/}
										<div className="col-xxl-8">
											{/*begin::Chart widget 38*/}
											<div className="card card-flush h-md-100">
												{/*begin::Header*/}
												<div className="card-header pt-7">
													{/*begin::Title*/}
													<h3 className="card-title align-items-start flex-column">
														<span className="card-label fw-bold text-gray-800">LOI Issued by Departments</span>
														<span className="text-gray-500 mt-1 fw-semibold fs-6">Counted in Millions</span>
													</h3>
													{/*end::Title*/}
													{/*begin::Toolbar*/}
													<div className="card-toolbar">
														{/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
														<div data-kt-daterangepicker="true" data-kt-daterangepicker-opens="left" className="btn btn-sm btn-light d-flex align-items-center px-4">
															{/*begin::Display range*/}
															<div className="text-gray-600 fw-bold">Loading date range...</div>
															{/*end::Display range*/}
															<i className="ki-outline ki-calendar-8 text-gray-500 lh-0 fs-2 ms-2 me-0"></i>
														</div>
														{/*end::Daterangepicker*/}
													</div>
													{/*end::Toolbar*/}
												</div>
												{/*end::Header*/}
												{/*begin::Body*/}
												<div className="card-body d-flex align-items-end px-0 pt-3 pb-5">
													{/*begin::Chart*/}
													<div id="kt_charts_widget_38_chart" className="h-325px w-100 min-h-auto ps-4 pe-6"></div>
													{/*end::Chart*/}
												</div>
												{/*end: Card Body*/}
											</div>
											{/*end::Chart widget 38*/}
										</div>
										{/*end::Col*/}
									</div>
									{/*end::Row*/}
									{/*begin::Row*/}
									<div className="row g-5 g-xl-10">
										{/*begin::Col*/}
										<div className="col-xxl-4">
											{/*begin::Chart widget 25*/}
											<div className="card card-flush h-md-100">
												{/*begin::Header*/}
												<div className="card-header pt-7">
													{/*begin::Title*/}
													<h3 className="card-title align-items-start flex-column">
														<span className="card-label fw-bold text-gray-900">Warephase stats</span>
														<span className="text-gray-500 pt-2 fw-semibold fs-6">8k social visitors</span>
													</h3>
													{/*end::Title*/}
													{/*begin::Toolbar*/}
													<div className="card-toolbar">
														{/*begin::Nav*/}
														<ul className="nav" id="kt_chart_widget_19_tabs">
															<li className="nav-item">
																<a className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light fw-bold px-4 me-1" data-bs-toggle="tab" id="kt_chart_widget_25_tab_1" href="#kt_chart_widget_25_tab_content_1">2024</a>
															</li>
															<li className="nav-item">
																<a className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light active fw-bold px-4" data-bs-toggle="tab" id="kt_chart_widget_25_tab_2" href="#kt_chart_widget_25_tab_content_2">Month</a>
															</li>
														</ul>
													</div>
													{/*end::Toolbar*/}
												</div>
												{/*end::Header*/}
												{/*begin::Body*/}
												<div className="card-body pt-0">
													{/*begin::Tab Content (ishlamayabdi)*/}
													<div className="tab-content">
														{/*begin::Tap pane*/}
														<div className="tab-pane fade" id="kt_chart_widget_25_tab_content_1">
															{/*begin::Chart*/}
															<div id="kt_charts_widget_25_chart_1" className="w-100 h-400px"></div>
															{/*end::Chart*/}
														</div>
														{/*end::Tap pane*/}
														{/*begin::Tap pane*/}
														<div className="tab-pane fade active show" id="kt_chart_widget_25_tab_content_2">
															{/*begin::Chart*/}
															<div id="kt_charts_widget_25_chart_2" className="w-100 h-400px"></div>
															{/*end::Chart*/}
														</div>
														{/*end::Tap pane*/}
													</div>
													{/*end::Tab Content*/}
												</div>
												{/*end::Body*/}
											</div>
											{/*end::Chart widget 25*/}
										</div>
										{/*end::Col*/}
										{/*begin::Col*/}
										<div className="col-xxl-8">
											{/*begin::Chart widget 24*/}
											<div className="card card-flush overflow-hidden h-md-100">
												{/*begin::Header*/}
												<div className="card-header py-5">
													{/*begin::Title*/}
													<h3 className="card-title align-items-start flex-column">
														<span className="card-label fw-bold text-gray-900">Human Resources</span>
														<span className="text-gray-500 mt-1 fw-semibold fs-6">Reports by states and ganders</span>
													</h3>
													{/*end::Title*/}
													{/*begin::Toolbar*/}
													<div className="card-toolbar">
														{/*begin::Menu*/}
														<button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
															<i className="ki-outline ki-dots-square fs-1"></i>
														</button>
														{/*begin::Menu 2*/}
														<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">
															{/*begin::Menu item*/}
															<div className="menu-item px-3">
																<div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick Actions</div>
															</div>
															{/*end::Menu item*/}
															{/*begin::Menu separator*/}
															<div className="separator mb-3 opacity-75"></div>
															{/*end::Menu separator*/}
															{/*begin::Menu item*/}
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">New Ticket</a>
															</div>
															{/*end::Menu item*/}
															{/*begin::Menu item*/}
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">New Customer</a>
															</div>
															{/*end::Menu item*/}
															{/*begin::Menu item*/}
															<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
																{/*begin::Menu item*/}
																<a href="#" className="menu-link px-3">
																	<span className="menu-title">New Group</span>
																	<span className="menu-arrow"></span>
																</a>
																{/*end::Menu item*/}
																{/*begin::Menu sub*/}
																<div className="menu-sub menu-sub-dropdown w-175px py-4">
																	{/*begin::Menu item*/}
																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">Admin Group</a>
																	</div>
																	{/*end::Menu item*/}
																	{/*begin::Menu item*/}
																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">Staff Group</a>
																	</div>
																	{/*end::Menu item*/}
																	{/*begin::Menu item*/}
																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">Member Group</a>
																	</div>
																	{/*end::Menu item*/}
																</div>
																{/*end::Menu sub*/}
															</div>
															{/*end::Menu item*/}
															{/*begin::Menu item*/}
															<div className="menu-item px-3">
																<a href="#" className="menu-link px-3">New Contact</a>
															</div>
															{/*end::Menu item*/}
															{/*begin::Menu separator*/}
															<div className="separator mt-3 opacity-75"></div>
															{/*end::Menu separator*/}
															{/*begin::Menu item*/}
															<div className="menu-item px-3">
																<div className="menu-content px-3 py-3">
																	<a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
																</div>
															</div>
															{/*end::Menu item*/}
														</div>
														{/*end::Menu 2*/}
														{/*end::Menu*/}
													</div>
													{/*end::Toolbar*/}
												</div>
												{/*end::Header*/}
												{/*begin::Card body*/}
												<div className="card-body pt-0">
													{/*begin::Chart*/}
													<div id="kt_charts_widget_24" className="min-h-auto" style={{height: "400px"}}></div>
													{/*end::Chart*/}
												</div>
												{/*end::Card body*/}
											</div>
											{/*end::Chart widget 24*/}
										</div>
										{/*end::Col*/}
									</div>
									{/*end::Row*/}
								</div>
								{/*end::Content container*/}
							</div>
							{/*end::Content*/}
						</div>
						{/*end::Content wrapper*/}
						{/*begin::Footer*/}
						<div id="kt_app_footer" className="app-footer">
							{/*begin::Footer container*/}
							<div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
								{/*begin::Copyright*/}
								<div className="text-gray-900 order-2 order-md-1">
									<span className="text-muted fw-semibold me-1">2024&copy;</span>
									<a href="https://keenthemes.com" target="_blank" className="text-gray-800 text-hover-primary">Keenthemes</a>
								</div>
								{/*end::Copyright*/}
								{/*begin::Menu*/}
								<ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
									<li className="menu-item">
										<a href="https://keenthemes.com" target="_blank" className="menu-link px-2">About</a>
									</li>
									<li className="menu-item">
										<a href="https://devs.keenthemes.com" target="_blank" className="menu-link px-2">Support</a>
									</li>
									<li className="menu-item">
										<a href="https://1.envato.market/EA4JP" target="_blank" className="menu-link px-2">Purchase</a>
									</li>
								</ul>
								{/*end::Menu*/}
							</div>
							{/*end::Footer container*/}
						</div>
						{/*end::Footer*/}
					</div>
	);
};

export default Products;
