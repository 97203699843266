import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../api/authRequests";
import useAuth from "../hooks/useAuth";
import { TokenObtainPair } from "../types/authTypes";

// Validation schema for login form
const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email("Yanlış email formatı")
		.min(3, "Minimum 3 simvol")
		.max(50, "Maksimum 50 simvol")
		.required("Email tələb olunur"),
	password: Yup.string()
		.min(3, "Minimum 3 simvol")
		.max(50, "Maksimum 50 simvol")
		.required("Şifrə tələb olunur"),
});

// Initial form values
const initialValues: TokenObtainPair = {
	email: "",
	password: "",
};

export function Login() {
	const [loading, setLoading] = useState(false);
	const { saveAuth } = useAuth();
	const navigate = useNavigate();

	// Formik setup for form handling
	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			try {
				const auth = await login(values);
				saveAuth(auth);
				navigate("/app/dashboard");
			} catch (error) {
				console.error(error);
				saveAuth(undefined);
				setStatus("Daxil edilmiş məlumatlar yanlışdır");
				setSubmitting(false);
				setLoading(false);
			}
		},
	});

	return (
		<form
			className="form w-100"
			onSubmit={formik.handleSubmit}
			noValidate
		>
			{/* Heading */}
			<div className="text-center mb-11">
				<h1 className="text-2xl font-bold text-gray-900 mb-3">
					Platforma Daxil Olun.
				</h1>
				<div className="text-gray-500 text-sm">
					E-mail ünvanınız və şifrənizi daxil edərək platforma daxil olun.
				</div>
			</div>

			{/* Form Group for Email */}
			<div className="fv-row mb-8">
				 
				<input
					placeholder="Email"
					{...formik.getFieldProps("email")}
					className={`bg-transparent border ${
						formik.touched.email && formik.errors.email
							? "border-red-500"
							: "border-gray-300"
					} form-control bg-transparent`}
					type="email"
					name="email"
					autoComplete="off"
				/>
				{formik.touched.email && formik.errors.email && (
					<div className="text-red-500 text-sm mt-1" style={{color:"#f8285a"}}>{formik.errors.email}</div>
				)}
			</div>

			{/* Form Group for Password */}
			<div className="fv-row mb-8">
				<input
				placeholder="Şifrə"
					type="password"
					autoComplete="off"
					{...formik.getFieldProps("password")}
					className={`bg-transparent border ${
						formik.touched.password && formik.errors.password
							? "border-red-500"
							: "border-gray-300"
					} form-control bg-transparent`}
				/>
				{formik.touched.password && formik.errors.password && (
					<div className="text-red-500 text-sm mt-1" style={{color:"#f8285a"}}>
						{formik.errors.password}
					</div>
				)}
			</div>

			{/* Wrapper for Links */}
			<div className="flex items-center justify-between mb-8">
				<div />
				<Link
					to="/auth/forgot-password"
					className="text-sm text-blue-600 hover:underline"
				>
					Şifrəni Unutmusuz?
				</Link>
			</div>

			{/* Submit Button */}
			<div className="d-grid mb-10">
				<button
					type="submit"
					className="btn btn-primary"
					disabled={formik.isSubmitting || !formik.isValid}
				>
					{!loading ? (
						<span>Daxil Ol</span>
					) : (
						<span>
							Zəhmət olmasa gözləyin...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
						</span>
					)}
				</button>
			</div>

			<div className="text-gray-500 text-center text-sm">
				Sualınız var?{" "}
				<Link to="/auth/registration" className="text-blue-600 hover:underline">
					Bizimlə əlaqə saxlayın.
				</Link>
			</div>
		</form>
	);
}
